// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isUnderMaintenance: false,
  isUnderMaintenanceFirebaseKey: 'BWMaintenanceDev',
  isWhiteLabel: true,
  maxBuyTicketsEvent: 10,
  minDocumentLength: 5,
  maxDocumentLength: 15,
  minEventCodeLength: 7,
  maxEventCodeLength: 8,
  supportEmail: 'soporte@blackid.app',
  landingUrl: 'https://landing.dev.blacktesting.com.ar/#/',
  apiAGUrl: 'https://services.blacktesting.com.ar/dev',
  billboardAGUrl: 'https://services.blacktesting.com.ar/dev',
  mediaUrl: 'https://media.dev.blacktesting.com.ar',
  downloadLink: 'https://blackid.com.ar/#/app/download-qr',
  linkToReturnToApp: 'https://links.blackid.app/kMLg',

  minPasswordLength: 6,
  maxPasswordLength: 30,
  maxDniLength: 15,
  minDniLength: 5,
  maxTableReferenceLength: 30,

  passwordRegex: '(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}',

  termsAndConditionsFile: 't&cBID.json',
  policiesFile: 'policiesBID.json',

  identification: '0f804f2f-69fb-11ef-8853-0e1eec3b3bc1',
  mentaTicketPublicKey: 'PUBLIC_023403ab890b8db9ebf98eb8e8b890',

  brandParams: {
    name: 'Floripa Summer',
    logo: {
      name: 'floripasummer.png',
      urlLink: 'https://billboard-floripasummer.dev.blacktesting.com.ar'
    },
    facebookUrl: 'https://www.facebook.com/profile.php?id=100085905443084',
    instagramUrl: 'https://www.instagram.com/floripasummerofficial/' //TODO
  },

  // QR SIGN
  algorithm: 'HS256',
  secretKey: `ef3b53d95f6b5c50525b7a4abfra34fd`,

  firebase: {
    apiKey: 'AIzaSyAkzftPPJtG2-0DAojMqdgREju2LJwiyjo',
    authDomain: 'blackid-1b732.firebaseapp.com',
    databaseURL: 'https://blackid-1b732.firebaseio.com',
    projectId: 'blackid-1b732',
    storageBucket: 'blackid-1b732.appspot.com',
    messagingSenderId: '356588914077',
    appId: '1:356588914077:web:2a0ae34f3a4e52bf525b74',
    measurementId: 'G-ZVKPEFRE5D'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
